import React, { useEffect, useState } from 'react';
import FileUploadComponent from './controls/FileUpload';
import DropdownComponent from './controls/DropDown';

function FinancesUploadInvestments() {
  const [data, setData] = useState([]);
  const [accountId, setAccountId] = useState('');

  useEffect(() => {
  }, []);

  const handleAccountChange = (accountId) => {
    setAccountId(accountId);
  };

  const handleBeforeUpload = (formData) => {
    formData.append('accountId', accountId);
  };

  const handleAfterUpload = (data) => {
    setData(data);
  };

  return (
    <div>
      <h1>Upload a financial file</h1>
      <DropdownComponent onOptionChange={handleAccountChange} endpoint="api/finances/getaccounts" text="Choose account if not Fidelity" />
      <br></br>
      <FileUploadComponent onBeforeUpload={handleBeforeUpload} onAfterUpload={handleAfterUpload} endpoint="api/finances/uploadinvestments" />
      <br /><br />
      <h5>Downloaded transactions - {data.length}</h5>
      {data &&
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>Date</th>
              <th>Symbol</th>
              <th>Type</th>
              <th>Quantity</th>
              <th>Basis</th>
              <th>USD Change</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((transaction, index) => (
              <>
                <tr key={index} style={{ borderBottom: '0px solid #fff' }}>
                  <td>{transaction?.date}</td>
                  <td>{transaction?.symbol}</td>
                  <td>{transaction?.type} {transaction?.subtype}</td>
                  <td>{transaction?.originalQuantity.toFixed(8)}</td>
                  <td>{transaction?.originalBasis.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{transaction?.usdChange}</td>
                </tr>
              </>
            ))}
          </tbody >
        </table>
      }
    </div>
  );
}

export default FinancesUploadInvestments;